.activity-container{
  height: 100vh;
  overflow-y: hidden !important;
}
.target-boxes{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
  width: 50%;
  margin: 5em auto;

  div{
    display: inline-block;
    height: 18.625rem;
    width: 18.625rem;
    margin-left: 5em;
    margin-right: 5em;

    svg{
      width: 100%;
      height: 100%;
    }
  }
}
.drag-box-container{
  margin-top: 10em;
}
.box-face {
  fill: #a67c52;
}

.green-sticker {
  fill: #5ffa71;
}
.red-sticker {
  fill: #ff5c5c;
}

.writing {
  fill: #7c7c7c;
}

.box-handle {
  fill: #543b29;
}

.lid-top {
  fill: #6b4b36;
}

.lid-front {
  fill: #896749;
}
.recap-section{
  .right{
    color: green;
  }
  .wrong{
    color: red;
  }
  display: flex;

  div{
    display: flex;
    flex-direction: column;
    width: 50%;
   

    ul{
      list-style-type: none;
      margin: 1em 0;
      text-align: left;
      margin: 1.5em;
      padding: .5em;

      li{
        margin: 1em 0;
      }
    }
  }
  div:first-child{
    border-right: 2px solid black;
  }
}

@media only screen and (max-width: 450px){
  .target-boxes{
    width: 100%;
    div{
      max-width: 80%;
    }
  }
}