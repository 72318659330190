.scenario-review{
  margin: 0 10vw;
  .comm-review{
    border-bottom: 1px solid $light-grey;
    .results-scenario-title{
      display: flex;
      flex-direction: row;
      align-items: center;
      h1{
        margin-right: 1em;
      }
    }
  }
  h1, h3{
    margin: 1em 0;
  }
  ul{
    padding: 0 5vw;
    margin-bottom: 2em;
  }
  li{
    margin: .8em 0
  }
}