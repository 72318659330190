.login-container{
  width: 30em;
  margin: 0 auto;
  margin-top: 5em;
  h1{
    margin: 0 0 1em 0;
    font-size: 3em;
  }
}
.login-form{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  margin: 1em;
  margin-left: 0;
}

.login-form input{
  margin-bottom: 1em;
  font-size: 16px;
  font-size: 1.8em;
}
.login-form label{
  font-size: 1.8em;
  margin-right: 1em;
  justify-self: start;  
}

.submit-button{
  grid-column: 2;
  justify-self: end;
}
 .loginError{
   color: #be1e2e;
   font-size: 2em;
   margin-top: -1em;
   text-align: center;
 }