.container{
  width: 100%;
  height: 7em;
  background-color: #454545;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .modTitle{
    margin: .5em auto;
    font-size: 1.3em;
    font-weight: 100;
    color: white;
  }
  .progressBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 3em;
    position: relative;
    text-align: center;
    margin: 0 auto;
    margin-bottom: .5em;
    padding-left: 3em;
    #rec{
      height: 100%;
      margin-right: 3em;
      z-index: 33;
      stroke: #369746;
      stroke-width: .2em;
    }
    .cls{
      stroke: #369746;
      stroke-miterlimit: 10;
      stroke-width: 6px;
    }
    .cls2{
      stroke: red;
      stroke-miterlimit: 10;
      stroke-width: 5px;
    }
    #circ{
      height: 100%;
      margin-right: 3em;
      z-index: 33;
    }
    #bar{
      height: 100%;
      width: calc(100% - 9em);
      position: absolute;
      left: 4em;
      stroke: #000;
      .barLine{
        stroke-width: 30;
      }
      .barLineColor{
        stroke: #369746;
        stroke-width: 30;
      }
    }
  }
}