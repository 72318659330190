@import '../../../style_sheets/variables';

.check-boxes{
  display: flex;
  align-items: center;
  flex-direction: row;
  
  label{
    margin: .4em 0;
    font-size: 1em;
    margin-left: 1em;
    display: flex;
    align-items: center;
  }

  input[type="checkbox"].checkCustom{
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  input[type='checkbox'].checkCustom ~ .checkmark {
    width: 2em;
    height: 2em;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: 2px solid $light-grey;
    cursor: pointer;
    border-radius: .4em;
  }

  // input[type='checkbox'].checkCustom:hover ~ .checkmark {
  //   border-color: $dark-grey;
  //   background-color: $light-grey
  // }

  input[type='checkbox'].checkCustom:checked ~ .checkmark {
    border-color: $lighter-blue;
    background: $light-blue url('../../../assets/svgs/white_check.svg') center no-repeat;
    background-size: 75%;
  }
  
  span{
    margin-right: 1em;
  }
}

@media (hover: hover) and (pointer: fine) {
  input[type='checkbox'].checkCustom:hover ~ .checkmark {
    border-color: $dark-grey;
    background-color: $light-grey
  }
}