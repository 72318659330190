.loaderContainer{
  height: calc(100vh - 6em);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .innerCont{
    width: 100vw;
    height: auto;
  }
}