@import 'variables';

.red-sub-headers{
  color: #BE1E2D;
  text-align: center;
  font-size: 2.5em;
  margin: 1.5em 0;
}

.edit-said-thing{
  display: flex;
  // justify-content: center;
  align-items: center;

  span{
    margin-left: 1em;
  }

  .edit-icon-wrap{
    height: 2em;
    width: 2em;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  
    svg{
      height: 80%;
      width: 80%;
    }
  }
}


.alt-dropdown-section:nth-child(even),
.alt-dropdown-section:nth-child(odd),
.bookmark-topic-section:nth-child(odd),
.bookmark-topic-section:nth-child(even){
  height: 6.45em;
  overflow: hidden;
  transition: .5s ease-in;
  cursor: pointer;
  h3{
    background-color: #D3D3D3;
    font-size: 2em;
    padding: 1em;
    color: #BE1E2D;
    padding: 1em 5.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .expand-icon{
    height: 1em;
    width: 1em;
    transition: .5s ease-in;
    svg{
      width: 100%;
      height: 100%;
      fill: black;
    }
  }
  .inner-section{
    font-size: 1.4em;
    line-height: 1.6em;
    height: auto;
    margin: 1em 8em;
    h4{
      font-size: 1.3em;
      margin: .8em 0em;
    }
  }
}

.alt-dropdown-section.panel-show-result{
  height: 14em;
  transition: .5s ease-in;
  .expand-icon{
    transform: rotate(90deg);
    transition: .5s ease-in;
  }
}
.alt-dropdown-section:nth-child(odd),
.bookmark-topic-section:nth-child(even){
  background-color: #D3D3D3;
}
.alt-dropdown-section:nth-child(even),
.bookmark-topic-section:nth-child(odd){
  h3{
    background-color: #fff;
  }
}
.button-container{
  display: flex;
  justify-content: flex-end;
  margin: 1em 10% 3em 0;
  button{
    margin: 0;
  }
}
.bookmark-topic-section.panel-show{
  height: 40em;
  transition: .5s ease-in;
  .expand-icon{
    transform: rotate(90deg);
    transition: .5s ease-in;
  }
}
.triangle{
  z-index:0
}

.dropdown-header{
  z-index:10
}