.outro-container{
  margin: 5vh 10vw;
  height: 100%;

  .header-box{
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    h1{
      font-size: 3em;
    }

    .circle-check-container{
      height: 8em;
      width: 8em;
      margin-left: 3em;

      svg{
        height: 100%;
        width: 100%;

      }
    }
  }

  p{
    font-size: 1.8em;
    line-height: 1.7em;
  }
}