.alertWrapper{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
  opacity: 0;

  .container{
    width: 42em;
    height: auto; 
    padding: 2em 2em;
    margin: 0 auto;
    background-color: #fff;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    position: fixed;
    text-align: center;
    z-index: 1000;
    box-shadow: 0px 0px 1.25em 0.125em rgba(0, 0, 0, 0.65);
    
    h1{
      font-size: 2em;
    }
    
    p{
      font-size: 1.5em;
      margin-top: 1.5em;
    }
    
    h1, p{
      margin-bottom: 1em;
    }
    
    button{
      margin-top: 1em;
      font-size: 1.5em;
    }

    .transitionCard{
      transform: scale(0.8);
      opacity: 0;
      color: #000;
    }

    .simple{
      transform: scale(0.8);
      opacity: 0;
    }

    .buttonBox{
      display: flex;
    }

    .green{
      background-color: rgb(103, 191, 103);
    }

    .red{
      background-color: rgb(228, 115, 115);
    }
  }
}

.dimmer{
  background-color: rgba(0, 0, 0, 0.65);
}

.bold{
  font-weight: 700;
}

@media only screen and (max-width: 850px) and (orientation: landscape){
  .container{
    max-height: 80vh;
    // top: calc(50% - 32vh);
  }
  .buttonBox{
    justify-content: center;
    button{
      margin: 0 .5em;
    }
  }
}