.intro-txt{
  margin: 2.5em 0;

  p{
    font-size: 1.7em;
    font-weight: 700;
    margin: 0 5vw;
    line-height: 1.5em;
  }

  a{
    font-size: 3em;
    text-decoration: none;
    color: $maroon;
  }
}
.skill-txt{
  text-align: center;
  margin: 2.5em 0;
}

.instruct-txt-center{
  text-align: center;
  margin: 10em 0;
}

.instruct-txt-alt{
  margin: 1em 5em;
}

@media (hover: hover) and (pointer: fine) {
  .intro-txt{
    a:hover{
      color: $light-grey;
    }
  }
}