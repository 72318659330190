.container{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3em;
  max-width: 40%;
  margin: 0 auto;
  padding: 1rem;
  background-color: grey;
  color: black;
  border-radius: 1rem;
}

.correctHit{
  background-color: rgb(125, 198, 125);
}

.incorrectHit{
  background-color: rgb(255, 106, 106);
}

@media only screen and (max-width: 450px){
  .container{
    max-width: 90%;
  }
}