.help-content{
  width: 100%;
  height: 100vh;
  padding: 2em 4em;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999999;

  .help-header{
    display: flex;
    justify-content: space-between;
  }

  .help-text-container{
    padding: 0 5vw;
  }

  .help-close-btn{
    height: 4em;
    width: 4em;
    cursor: pointer;
    svg{
      height: 100%;
      width: 100%;
    }
  }
}
.help-show{
  top: 0;
  transition: .5s ease-in;
}
.help-hide{
  bottom: 0;
  transition: .5s ease-in;
}

