.container{
  width: 100%;
  height: 7em;
  background-color: #454545;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .progressBar{
    display: flex;
    justify-content: space-between;
    height: 5em;
    position: relative;
    margin-left: 3em;
    .nums{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      height: 100%;
      width: calc(100% - 3em);
      padding: 0 1.8em;
      p{
        color: white;
        font-size: 2.5rem;
        z-index: 9999;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
    #rec{
      height: 100%;
      margin-right: 3em;
      z-index: 33;
      stroke: #369746;
      stroke-width: .2em;
    }
    .cls{
      stroke: #369746;
      stroke-miterlimit: 10;
      stroke-width: 6px;
    }
    .cls2{
      stroke: red;
      stroke-miterlimit: 10;
      stroke-width: 5px;
    }
    #circ{
      height: 100%;
      margin-right: 3em;
      z-index: 33;
    }
    #bar{
      height: 100%;
      width: calc(100% - 3.5em);
      position: absolute;
      left: .1em;
      stroke: #000;
      .barLine{
        stroke-width: 30;
      }
      .barLineColor{
        stroke: #369746;
        stroke-width: 30;
      }
    }
  }
}