@import '../style_sheets/variables';

.header-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.reminder-form-wrap{
  background-color: $light-grey;
  padding: 1em 10vw;
  
  form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto;
    font-size: 1.4em;
    
    label{
      margin: 1em 0;
    }
    
    select{
      font-size: 1.2em;
    }
    
    .time-wrap{
      display: flex;
      flex-direction: row;
    }
  }
}