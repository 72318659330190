@import 'variables';

.module-links{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 0 12%;
  font-size: 1.5em;
  justify-content: space-between;
  
  .module-start-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    button{
      margin-left: 1em;
      font-size: 1.1em; 
    }
  }
}

.module-links:nth-child(even){
  background-color: $light-grey;
}

.intro-popup-list{
  font-size: 1.3em;
}

@media only screen and(max-width: 450px){
  .module-links{
    font-size: 1.8em;
    flex-direction: column;
    padding: 0 12%;
    align-items: unset;
    h1{
      margin-top: .5em;
      font-size: 1.2em;
    }
  }
}