.inner-page-content{
  margin: 0 10vw;
  
  p{
    font-size: 1.5em;
    margin-bottom: .5em;
  }
}
.col-page{
  display: grid;
  grid-template-columns: 2fr 3fr;
  margin-top: 4em;
  grid-gap: 3em;
}
.content-left{
  grid-column: 1;
  padding: 1em 0;
  max-width: 44em;

  .vid-summary{
    padding-right: .8em;
    margin-bottom: 1.7em;
  }

  ul{
    list-style-type: none;
    font-size: 1.5em;
  }

  li{
    margin: .5em 0;
  }

  li:before{
    content: "•"; 
    padding-right: 1em;
    color: $light-blue; 
  }
}
.content-right{
  grid-column: 2;
  padding: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .video-player-container{
    height: auto;
    width: 100%;
  }
}
.button-box{
  display: flex;
  justify-content: flex-end;
  margin: 3em 0 3em 0;

  button{
    margin: 0;
    margin-left: 1em;
  }
}