.scenarios{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  text-align: center;
  margin-top: 3em;
  
  .scenario-card{
    height: 10rem;
    width: 100%;
    border: 2px solid $dark-grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5em;
    border-radius: .5em;
    font-size: 120%;
  }
  
  .scenario-card.active{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2% 20%;
    display: inline-block;
    text-align: left;
    background-color: white;
    z-index: 99;
    border: none;
    border-radius: 0;
    font-size: 1.3em;
    
    h1{
      margin-bottom: 1em;
    }
    
    p{
      margin-bottom: 1em;
    }
    
    button{
      font-size: 1.3em;
    }
    
    .inner-back-button{
      margin-bottom: 2em;
      button{
        margin: 0;
      }
    }
    
    form{
      display: flex;
      flex-direction: column;
      
      input{
        margin: .7em 0;
        font-size: 1.5em;
        width: 70%;
      }
      
      #other-field-label{
        margin: .5em 0;
        font-style: italic;
      }
    }
  }
  
  .scenario-card.highlight{
    background-color: $maroon;
    color: $light-grey;
  }
  
  .scenario-card.active:hover{
    background-color: white;
    color: black;
  }
}

@media(hover: hover) and (pointer: fine) {
  .scenario-card:hover{
    background-color: $maroon;
    color: $light-grey;
  }
}