@import 'variables';

.vid-act-container{
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.video-player{
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 32em;
  width: 57em;
  margin: 0 auto;
  margin-bottom: 2em;
}