@import 'variables';

.inner-content-alt{
  margin: 0 10%;
  h2{
    font-size: 2em;
    margin: 1em 0;
    margin-bottom: .5em;
    padding-left: 1em;
  }
  p{
    padding: 1em 1em;
    font-size: 1.5em;
  }
  ul{
    font-size: 1.5em;
    line-height: 1.35em;
    padding: 1em 2em;
    list-style-type: none;
    li{
      margin: .8em 0;
    }
    li:before{
      content: "•"; 
      padding-right: 1em;
      color: $light-blue; 
    }
  }
  .red-header{
    color: #BE1E2D;
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 1.5em;
  }
}
.dropdown-section-alt:nth-child(even),
.dropdown-section-alt:nth-child(odd){
  height: 6.45em;
  overflow: hidden;
  transition: .5s ease-in;
  cursor: pointer;
  h3{
    background-color: #D3D3D3;
    font-size: 2em;
    padding: 1em;
    color: #BE1E2D;
    padding: 1em 5.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .expand-icon{
    height: 1em;
    width: 1em;
    transition: .5s ease-in;
    svg{
      width: 100%;
      height: 100%;
      fill: black;
    }
  }
  .inner-section-alt{
    font-size: 1.4em;
    line-height: 1.6em;
    height: auto;
    margin: 1em 8em;
    ul{
      list-style-type: none;
    }
    li{
      margin: .5em 0;
    }
    li:before{
      content: "•"; 
      padding-right: 1em;
      color: $light-blue; 
    }
    h4{
      font-size: 1.3em;
      margin: .8em 0em;
    }
    input{
      width: 60%;
      margin-top: 1em;
      font-size: 1em;
    }
  }
}
.dropdown-section-alt.panel-show,
.dropdown-section-alt.panel-show-result{
  height: 66em;
  transition: .5s ease-in;
  .expand-icon{
    transform: rotate(90deg);
    transition: .5s ease-in;
  }
}
.dropdown-section-alt.panel-show-result{
  height: 14em;
}
.dropdown-section-alt:nth-child(odd){
  background-color: #D3D3D3;
}
.dropdown-section-alt:nth-child(even){
  h3{
    background-color: #fff;
  }
}
.button-container-alt{
  display: flex;
  justify-content: flex-end;
  margin: 3em 0 3em 0;
  .button-row{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button{
      margin: 0;
      font-size: 1em;
    }
  }
}
.idea-edit[data-edit-src="5"],
.idea-edit[data-edit-src="6"]{
  opacity: 0;
  height: 0;
  display: none;
}