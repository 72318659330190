.not-found-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(100vh - 6em);
  margin: auto 15%;
  .logo-underlay{
    height: auto;
    width: 100%;
    position: absolute;
    top: 25%;
    h1{
      position: absolute;
      font-size: 8em;
      z-index: 9;
      text-align: center;
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-left: 0;
      top: 5rem;
      left: 0;
      right: 0;
    }
    svg{
      height: 100%;
      width: 100%;
      opacity: .2;
    }
  }
}