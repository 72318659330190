@import 'variables';

.inner-content{
  margin: 0 10%;
  h2{
    font-size: 2.5em;
    margin: 1em 0;
    margin-bottom: .5em;
  }
  p{
    padding: 1em 1em;
    font-size: 1.5em;
  }
  ol{
    font-size: 1.5em;
    line-height: 1.35em;
    padding: 1em 2em;
    li{
      margin: .8em 0;
    }
  }
}
.dropdown-section:nth-child(even),
.dropdown-section:nth-child(odd){
  height: 6.45em;
  overflow: hidden;
  transition: .5s ease-in;
  cursor: pointer;
  h3{
    background-color: #D3D3D3;
    font-size: 2em;
    padding: 1em;
    color: #BE1E2D;
    padding: 1em 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .expand-icon{
    height: 1em;
    width: 1em;
    transition: .5s ease-in;
    svg{
      width: 100%;
      height: 100%;
      fill: black;
    }
  }
  .inner-section{
    font-size: 1.7em;
    line-height: 1.6em;
    height: auto;
    margin: 1em 10vw;
    ul{
      list-style-type: none;
    }
    li{
      margin: .5em 0;
    }
    li:before{
      content: "•"; 
      padding-right: 1em;
      color: $light-blue; 
    }
    h4{
      font-size: 1.5em;
      margin: .8em 0em;
    }
  }
}
.dropdown-section.panel-show{
  height: 38em;
  transition: .5s ease-in;
  .expand-icon{
    transform: rotate(90deg);
    transition: .5s ease-in;
  }
}
.dropdown-section:nth-child(even){
  background-color: #D3D3D3;
}
.dropdown-section:nth-child(odd){
  h3{
    background-color: #fff;
  }
}
.button-container{
  display: flex;
  justify-content: flex-end;
  margin: 3em 10% 3em 0;
  button{
    margin: 0;
  }
}
