@import 'variables';

.menu-panel{
  position: absolute;
  top: 6em;
  left: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  border-left: 1px solid #000;
}
.menu-link{
  height: 7em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #000;
  a{
    display: inline-block;
    text-decoration: none;
    font-size: 2.5em;
    width: 100%;
    margin-right: 2em;
    color: #000;
    text-align: right;
  }
}
.link-3{ // margin hack due to arrow icon
  a{
    margin-right: .7em;
  }
}
.sub-menu{
  height: 0;
  visibility: hidden;
  border-bottom: 1px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  transition: .5s ease-in-out;
  overflow: hidden;
  z-index: -3;
  .sub-link{
    margin: .5em;
    a{
      font-size: 1.8em;
      margin: 1em;
      text-decoration: none;
      margin: 3em;
      color: grey;
      pointer-events: none;
      text-decoration: line-through;
    }
    .unlocked-link{
      cursor: pointer;
      color: #000;
      pointer-events: unset;
      text-decoration: none;
    }
    .unlocked-link:hover{
      color: $maroon;
    }
  }
}
.arrow-icon{
  width: 2em;
  height: 2em;
  margin-right: 1em;
  transition: .5s ease-in-out;
  svg{
    width: 100%;
    height: 100%;
    fill: black;
    stroke: black;
  }
}
.rotate{
  transform: rotate(90deg);
  transition: .5s ease-in-out;
}
.revealed{
  height: 15em;
  transition: .5s ease-in-out;
  visibility: visible;
}
.menu-link:hover{
  background-color: #7DD7FC;
}
.open{
  left:0;
  width: 100%;
  overflow-y: visible;
  transition: .5s ease-out;
}
.closed{
  width: 100%;
  overflow-y: visible;
  transition: .5s ease-in; 
}
