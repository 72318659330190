@import 'variables';

.act-container{
  height: calc(100vh - 13em);
  width: auto;
  margin: 0 auto;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  touch-action: none;
  overflow: hidden;
}

.click-swipe-btn-right,
.click-swipe-btn-left{
  position: absolute;
  height: 5rem;
  width: 5rem;
  margin: 0 1rem;
  border-radius: 50%;
  border: .2rem solid $dark-grey;
  background-color: $light-grey;
  cursor: pointer;

  &:hover{
    background-color: $dark-grey;

    svg{
      fill: $light-grey;
    }
  }

  svg{
    fill: $dark-grey;
  }
}

.click-swipe-btn-right{
  right: 3rem;
  top: 50%;
}
.click-swipe-btn-left{
  left: 3rem;
  top: 50%;
}

.txt-holder{
  min-height: 3em;
  width: 70%;
  font-size: 1.5em;
  color: $maroon;
  background-color: $light-grey;
  // border: 3px solid $maroon;
  position: absolute;
  top: 15%;
  left: 15%;
  margin: 0 auto;
  border-radius: 1em;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  cursor: pointer;
  padding: 1em;
  
  p{
    font-size: 1.1em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    touch-action: none;
  }
}

.swipe-holder{
  height: 20em;
  width: 15em;
  font-size: 1.5em;
  box-shadow: 0px 10px 30px 0px rgb(54, 53, 53);
  position: absolute;
  top: 5vh;
  left: calc(50% - 7.5em);
  margin: 0 auto;
  margin-top: 1em;
  border-radius: .3em;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
  cursor: pointer;
  padding: .1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 1em;

  .swipe-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15em;
    margin: .8em 0;
    user-select: none;

    img{
      height: auto;
      width: 100%;
      user-select: none;
    }
  }
}

.act-feedback-cont{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2em 8vw;
  opacity: 1;
  transition: opacity 1s ease-in;

  h1{
    font-size: 3em;
    font-weight: 700;
    margin-bottom: 1.5em;
    text-align: center;
  }

  p{
    font-size: 2em;
    font-weight: 700;
    line-height: 1.4em;
    text-align: center;
  }
}

.left-swipe-instruct,
.right-swipe-instruct{
  position: absolute;
  font-size: 1.8em;
  color: $dark-grey;
}

.left-swipe-instruct{
  left: 5vw;
  bottom: 3vw;
}

.right-swipe-instruct{
  right: 5vw;
  bottom: 3vw;
}