@import 'variables';

.reminder-prompt{
  h1{
    font-size: 2em;
  }
  margin: 1em 0;
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 2px solid $light-grey;
}
