@import '../../../style_sheets/variables';

.container{
  width: 100%;
  height: 6em;
  padding: .3em 1em;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.logo{
  height: auto;
  width: 19em;
  margin-left: 2em;
  svg{
    height: 100%;
    width: 100%;
  }
}
.icons{
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: .3em;
  margin-right: 1em;
  div{
    margin: 0 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: end;
    cursor: pointer;
  }
  .helpIcon{
    height: 4em;
    width: 4em;
    border: .3em solid black;
    border-radius: 1em;
    font-family: $main-font;
    font-weight: 900;
    p{
      font-size: 3em;
    }
  }
  .burgCont{
    height: 5em;
    width: 5em;
  }
}

