.slideshow-container{
  height: 40em;
  width: 100%;
  padding: 3em 5%;
  background-color: $light-grey;
  display: flex;
  flex-direction: column;
  justify-content: left ;
  align-content: center;
  z-index: -1;

  .main-image{
    position: relative;
    background-color: $light-grey;
    min-height: 30em;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .snugger{
     
      position: absolute;
      display: flex;
      justify-content: center;
      background-color: white;
      
      .car-img{
        
        height: 30em;
        width: auto;
      }

      .fight-img{
        height: 30em;
      }

      .mom-img{
        height: 30em;
      }

      .hidden{
        display: none;
        opacity: 0;
      }

      .hotspot{
        height: 2.5em;
        width: 2.5em;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        transition: .3s ease-in-out;
      }
    }
  }
}
.button-box-split{
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: row;
}
@media only screen and (max-width: 800px){

}
@media only screen and (max-width: 450px) {
  .button-box-split{
    position :relative;
    display: flex;
    justify-content: space-between;
    align-items: left;
    top: -11vw;
    // background-color: green;
    flex-direction: row;
  }
}


// HOTSPOT CLASSES/LOCATIONS //

.hs1-1{
  top: 64%;
  left: 42%;
}

.hs1-2{
  top: 55%;
  left: 67%;  
}

.hs1-3{
  top: 50%;
  left: 8%;
}

.hs2-1{
  top: 30%;
  left: 20%;
}

.hs2-2{
  top: 70%;
  left: 55%;
}

.hs2-3{
  top: 15%;
  left: 75%;
}

.hs3-1{
  top: 40%;
  left: 60%;
}

.hs3-2{
  top: 15%;
  left: 30%;
}

.hs3-3{
  top: 60%;
  left: 70%;
}

.hotspot.not-revealed{
  animation: pulse 1s linear infinite;
  border: .2em solid rgb(187, 104, 104);
  transition: 1s ease-in-out;
}

.hotspot.revealed{
  border: .2em solid lightgreen;
  background-color: green;
  transition: 1s ease-in-out; 
  animation: none;
}


//KEYFRAME ANIMATIONS

@keyframes pulse {
	0% { background-color: rgb(187, 104, 104) }
	50% { background-color: rgb(104, 17, 17) }
	100% { background-color: rgb(187, 104, 104) }
} 
@keyframes revealPulse {
	0% { background-color: rgb(104, 187, 104) }
	50% { background-color: rgb(17, 104, 17) }
	100% { background-color: rgb(104, 187, 104) }
} 


@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px){

  // .button-box-split{

  //   border: solid grey;
  //   // display: initial;
  //   justify-content: space-between;
  //   align-items: left;
  //   margin-bottom: 100px;
  //   // flex-direction: row-reverse;
  //   // background-color:pink;
   
  // }
  .slideshow-container{
    .main-image{
      .snugger{
        // margin-left: -300px;
      }
    }
  }
}
