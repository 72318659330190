@import '../../../style_sheets/variables';

.container{
  opacity: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 5em;
  width: 5em;
  background-color: $light-blue;
  margin: 0;
  border-top-right-radius: 2em;
  padding: 1em;
  transition: .3s ease-in-out;
  cursor: pointer;

  .iconWrapper{
    height: 100%;
    width: 100%;

    svg{
      height: 100%;
      width: 100%;
      fill: #fff;
    }
  }

  .languageList{
    justify-content: flex-start;
    width: 100%;

    .topBar{
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 2em 0;
    }
    
    .closeIconWrap{
      position: absolute;
      top: 1.3vw;
      right: 1.3vw;
      height: 4em;
      width: 4em;
      border: 1px solid #fff;
      border-radius: 50%;
      align-self: flex-end;
      cursor: pointer;

      svg{
        fill: #fff;
      }
    }

    li{
      list-style-type: none;
      font-size: 1.7em;
      color: #000;
      width: 100%;
      height: 2em;
      pointer-events: unset;
      border-top: 1px solid #fff;
      padding: 2em .3em;
      display: flex;
      align-items: center;

      &:hover{
        background-color: lightblue;
        color: $dark-grey;
      }
    }
  }
}

.hidden{
  opacity: 0;
  display: none;
}

.expanded{
  height: 21em;
  width: 20em;
  transition: .3s ease-in-out;
  padding: unset;
  background-color: $lighter-blue
}