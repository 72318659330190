.about-container{
  margin: 5vh 10vw;
  height: 100%;

  h1{
    font-size: 3em;
    margin-bottom: 1em;
  }

  p{
    font-size: 1.8em;
    line-height: 1.7em;
  }
}