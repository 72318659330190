.overview{
  padding: 0 10vw;
}
.conclude-header{
  margin: 2em 0 1em 0;
  font-size: 2.5em;
}
.conclude-txt{
  margin: 1em auto;
}
.conclude-points{
  li{
    margin: 1em 0;
    padding: .3em;
    font-size: 1.45em;
    margin-left: 1em;
  }
}