.cls-1, .cls-2, .cls-4 {
  fill: none;
}

.cls-2 {
  stroke: #D3D3D3;
  stroke-width: 8px;
}

.cls-2, .cls-4 {
  stroke-miterlimit: 10;
}

.cls-3 {
  clip-path: url(#clip-path);
}

.cls-4 {
  stroke: #5ffa71;
  stroke-width: 44px;
}