@import 'variables';


.custom-act-input{
  margin: 0 10%;
  height: 100%;
  margin-top: 2em;

  h3{
    display: flex;
    margin: 3em 0 0 0;
  }

  input{
    font-size: 1.3em;
    width: 50%;
  }
}
.main-act-wrap{
  margin: 0 10%;
  height: 100%;
  margin-top: 2em;
  

  

  .topic-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2em;

    .topic-btn{
      cursor: pointer;
      font-size: 1.1em;
      margin: 0 1em;
      display: flex;
      text-align: center;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      padding: 1em;
      height: auto;
      width: 8em;
      background-color: tomato;
      border-radius: .3em;
      transition: .2s ease-in-out;
    }
    // .topic-btn:hover{
    //   transform: scale(1.04);
    //   transition: .2s ease-in-out;
    //   box-shadow: 2px 5px 20px 5px rgb(97, 95, 95);
    // }
    .topic-btn:nth-child(1){
      background-color: rgb(240, 168, 168);
    }
    // .topic-btn:nth-child(1):hover{
    //   background-color: rgb(237, 120, 120);
    // }
    .topic-btn:nth-child(2){
      background-color: rgb(242, 209, 149);
    }
    // .topic-btn:nth-child(2):hover{
    //   background-color: rgb(244, 185, 75);
    // }
    .topic-btn:nth-child(3){
      background-color: rgb(228, 228, 167);
    }
    // .topic-btn:nth-child(3):hover{
    //   background-color: rgb(230, 230, 85);
    // }
    .topic-btn:nth-child(4){
      background-color: rgb(197, 230, 197);
    }
    // .topic-btn:nth-child(4):hover{
    //   background-color: rgb(124, 233, 124);
    // }
    .topic-btn:nth-child(5){
      background-color: rgb(247, 196, 247);
    }
    // .topic-btn:nth-child(5):hover{
    //   background-color: rgb(245, 122, 245);
    // }
    .topic-btn:nth-child(6){
      background-color: rgb(172, 172, 244);
    }
    // .topic-btn:nth-child(6):hover{
    //   background-color: rgb(118, 118, 247);
    // }
    .topic-btn:nth-child(7){
      background-color: rgb(201, 232, 242);
    }
    // .topic-btn:nth-child(7):hover{
    //   background-color: rgb(126, 210, 238);
    // }
    .topic-btn.active-tag{
      background-color: red;
      box-shadow: 2px 5px 20px 5px rgb(135, 133, 133);    
      transform: scale(1.04); 
    }
    // .topic-btn.active-tag:hover{
    //   background-color: red;
    //   box-shadow: 2px 5px 20px 5px rgb(97, 95, 95);
    //   transform: scale(1.04);
    // }
  }
  .act-list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr; 
    grid-gap: .4em;
    padding: 0 .7em;

    .activity-item{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 6em;
      width: 100%;
      padding: 2em;
      border-radius: 1em;
      background-color: #a49d9d;
      transform: translateY(100px);
      transform: scale(.3);
      opacity: 0;
      p{
        pointer-events: none;
        user-select: none;
      }
    }
    // .activity-item:hover{
    //   background-color: rgb(220, 213, 213); 
    // }
    .hidden-tag{
      display: none;
    }
    .act-chosen{
      background-color: rgb(220, 213, 213);
      border: .1em solid rgb(133, 38, 38);
    }
  }
}
.instruct-txt,
.instruct-txt-center{
  margin: 2em 5vw;
  p{
    font-size: 1.4em;
    font-weight: 700;
  }
  a{
    font-size: 3em;
    text-decoration: none;
    color: $maroon;
  }
  // a:hover{
  //   color: $light-grey;
  // }
}
.instruct-txt-center{
  text-align: center;
  margin: 10em 0;
}
.instruct-txt-alt{
  margin: 1em 5em;
}

@media (hover: hover) and (pointer: fine) {
  .topic-btn:hover{
    transform: scale(1.04);
    transition: .2s ease-in-out;
    box-shadow: 2px 5px 20px 5px rgb(97, 95, 95);
  }
  .topic-btn:nth-child(1):hover{
    background-color: rgb(237, 120, 120);
  }
  .topic-btn:nth-child(2):hover{
    background-color: rgb(244, 185, 75);
  }
  .topic-btn:nth-child(3):hover{
    background-color: rgb(230, 230, 85);
  }
  .topic-btn:nth-child(4):hover{
    background-color: rgb(124, 233, 124);
  }
  .topic-btn:nth-child(5):hover{
    background-color: rgb(245, 122, 245);
  }
  .topic-btn:nth-child(6):hover{
    background-color: rgb(118, 118, 247);
  }
  .topic-btn:nth-child(7):hover{
    background-color: rgb(126, 210, 238);
  }
  .topic-btn.active-tag:hover{
    background-color: red;
    box-shadow: 2px 5px 20px 5px rgb(97, 95, 95);
    transform: scale(1.04);
  }
  .act-list{
    .activity-item:hover{
      background-color: rgb(220, 213, 213); 
    }
  }
  .instruct-txt,
  .instruct-txt-center{
    a:hover{
      color: $light-grey;
    }
  }
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px){
  .custom-act-input{
    h3{
    
      margin: 1em 0 1em 0;
      float:left

    }
    
    input{
      margin: 0 0 1em 0;
      font-size: 1.3em;
      width: 100%;
    }
  }
  
  .main-act-wrap{
   
    margin: 0 10%;
    height: 10%;
    margin-top: 2em;
   
    .act-list{
      // display: grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
      // grid-template-rows: 1fr 1fr 1fr 1fr; 
      // grid-gap: .4em;
      // padding: 0 .7em;

      .activity-item{
        width: 20%;
        float: left;
        border: solid white;
    

      }
    }
  }
}
