@import 'variables';

.col-section{
  margin-top: 5em;

  .desc-txt{
    padding-right: 5em;
    
    ul{
      list-style-type: none;
      font-size: 1.5em;
    }

    li{
      margin: .5em 0;
    }

    li:before{
      content: "•"; 
      padding-right: 1em;
      color: $light-blue; 
    }
  }

  .support-form-container{
    padding-left: 5em;
    display: grid;
    grid-template-columns: 1fr;
  }

  .support-form{
    font-size: 1.5em;
    .input-section{
      display: flex;
      flex-direction: column;
      label{
        margin-bottom: .3em;
      }
      input{
        font-size: 1.2em;
        margin-bottom: 1em;
      }
    }
  }

  .submit-container{
    display: flex;
    justify-content: flex-end;
    button{
      margin: 0;
    }
  }
}

.bold{
  font-weight: 700;
}

.hidden{
  display: none;
}

.inner-section{
  ul{

    overflow: auto;
  }
}


div.dropdown-section.panel-show{

.inner-section{
  margin-top: 6em;
}
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow:scroll;
    h3{
      
     position: absolute;
     width: 100%;
    }
  
  
}
.hide{
  display: none;
}

div.dropdown-section.panel-show::-webkit-scrollbar{
  display: none;
}

// h3{
//   position: initial;
// }