.profileParagraph {
  
    width: 50%;
    font-size: 3vh;
    margin:0 auto;
    margin-top: 10vh;
    background-color:white;
    padding: 15px;
    border:solid black 2px;
    border-radius:10pt;
}
.sideBar{
   border:solid black 2px;
   width: 25vw;
   position: absolute;
   z-index: 2;
   left: 10vw;
   background-color: white;
   top: 5vh;
   text-align: center;
   height: 75vh;
   border-radius: 10pt;
}

.paragraphContainer{
    width: 100vw;
    
    position: absolute;
    right: -15vw;
    height: 100vh;
    z-index: 1;
    top: -5vh;
    background-color: $light-grey;
}

.passwordChangeButton{
    border: none;
    margin:   0 auto;
    cursor: pointer;
    background-color: $light-blue;
    color: white;
    padding: 10px;
    border-radius: 5pt;
    margin-top: 20px;
    
}
.passwordChangeButton:hover{
    opacity: .7;
}
.header{
    margin: 0 auto;
    width: 25vw;
    text-align: center;
    margin-top: 10vh;
}
.space{
    height: 20%
}

.profile-container{
    background-color: $light-grey;
    height: 100vh;
}

@media only screen and(max-width: 850px){
    .profileParagraph {
  
        // width: 50%;
        width: 70%;
        font-size: 3vh;
        margin:0 auto;
        margin-top: 10vh;
        background-color:white;
        padding: 15px;
        border:solid black 2px;
        border-radius:10pt;
        position: absolute;
        left: 0px;
    }
    .sideBar{
       border:solid black 2px;
       width: 100vw;
       position: absolute;
       z-index: 2;
       display:none;
    //    left: 10vw;
       background-color: white;
       top: initial;
       text-align: center;
       height: 30vh;
       left: 0px;
       border-radius: 10pt;
        bottom: -200px;
    }
    
    .paragraphContainer{
        // width: 100vw;
        // width: 200vw;
        position: absolute;
        right: -15vw;
        // height: 100vh;
        z-index: 1;
        top: -5vh;
        background-color: white;
        // background-color: $light-grey;
    }
    
    .passwordChangeButton{
        border: none;
        margin:   0 auto;
        cursor: pointer;
        background-color: $light-blue;
        color: white;
        padding: 10px;
        border-radius: 5pt;
        margin-top: 20px;
        
    }
    .passwordChangeButton:hover{
        opacity: .7;
    }
    .header{
        margin: 0 auto;
        width: 25vw;
        text-align: center;
        margin-top: 10vh;
    }
    .space{
        height: 20%
    }
    
    .profile-container{
        background-color: white;
        height: 100%;
        // width: 100%;
    }

  
    
}
