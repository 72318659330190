.playerContainer{
  width: 100%;
  height: 100%;
  position: relative;

  video{
    width: 100%;
    height: 100%;
  }

  .hotspot{
    width: 100%;
    height: 100%;
    // max-height: 32em;
    // max-width: 57em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    background-color: black;
    cursor: pointer;
  }

  .bigCtrlBtn{
    position: absolute;
    top: calc(50% - 7.5em);
    left: calc(50% - 7.5em);
    height: 15em;
    width: 15em;
    cursor: pointer;
  }

  .bigCtrlBtn.play{
    background-image: url('../../../assets/svgs/big_play_btn.svg');
  }

  .bigCtrlBtn.paused{
    background-image: url('../../../assets/svgs/big_pause_btn.svg');
  }

  .bigCtrlBtn.replay{
    background-image: url('../../../assets/svgs/big_replay_btn.svg');
  }
} 