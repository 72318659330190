.alertWrapper{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
  opacity: 0;

  .container{
    width: 42em;
    max-width: 90vw;
    height: auto; 
    padding: 2em 2em;
    margin: 0 auto;
    background-color: #fff;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    position: fixed;
    text-align: center;
    z-index: 1000;
    box-shadow: 0px 0px 1.25em 0.125em rgba(0, 0, 0, 0.65);
    
    h1{
      font-size: 2em;
    }
  
    p{
      font-size: 1.5em;
    }
  
    ul{
       font-size: 1.2em;
    }
  
    h1, p{
      margin-bottom: 1em;
    }
  
    button{
      margin-top: 2em;
    }
    
    ol{
      padding: 1em;
      
      li{
        text-align: left;
        margin: .8em 0;
      }
    }
  }

  .green{
    border: 2em solid rgb(103, 191, 103);
  }
  
  .red{
    border: 2em solid rgb(228, 115, 115);
  }

  .wideAlert{
    min-width: 42em;
    max-width: 80vw;
    height: auto; 
    max-height: 95vh;
    padding: 2em 2em;
    margin: 0 auto;
    background-color: #fff;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    position: fixed;
    text-align: center;
    box-shadow: 0px 0px 1.25em 0.125em rgba(0, 0, 0, 0.65);
    
    h1{
      font-size: 2em;
    }
    
    p{
      font-size: 1.5em;
    }
    
    ul{
       font-size: 1.2em;
    }
    
    h1, p{
      margin-bottom: 1em;
    }
    
    button{
      margin-top: 2em;
    }
    
    ol{
      padding: 1em;
      
      li{
        text-align: left;
        margin: .8em 0;
      }
    }
    
    .transitionCard{
      transform: scale(0.8);
      opacity: 0;
      color: #000;
    }
    
    .simple{
      transform: scale(0.8);
      opacity: 0;
    }
  }
}

.dimmer{
  background-color: rgba(0, 0, 0, 0.65);
}

.bold{
  font-weight: 700;
}

@media only screen and (max-width: 850px) and (orientation: landscape){
  .container{
    width: 70vw;
    max-height: 40em;
    // top: calc(50% - 25vh);
  }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px){
  
  .alertWrapper{
  .container{
    position: initial;
  }

  .wideAlert{
    position: initial;
  }
}
}