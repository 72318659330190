@import 'variables';
@import "~video-react/styles/scss/video-react";
@import 'login';
@import 'menu';
@import 'help';
@import 'home';
@import 'life';
@import 'm1p1';
@import 'm1p2';
@import 'm1p3';
@import 'm1p4';
@import 'm1_conclude';
@import 'bookmarks';
@import 'm1_results';
@import 'notfound';
@import 'm2p1';
@import 'm2p3';
@import 'm2_results';
@import 'reminders';
@import 'm4p3';
@import 'm4p1';
@import 'm4p5';
@import 'm3p2';
@import 'm4_results';
@import 'about';
@import 'outro_page';
@import 'profile';
@import 'support';
@import 'resources';


*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // PREVENTS BLUE FLASH ON MOBILE TOUCHES
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);  // PREVENTS BLUE FLASH ON MOBILE TOUCHES
}
html{
  font-size: 1vw;
  height: 100%;
  overflow: auto;
}
body{
  font-family: $secondary-font;
  height: 100%;
}
h1{
  font-family: $main-font;
}
a{
  outline: none;
}
.wrapper{
  height: 100%;
  width: auto;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

.page-content{
  position: relative;
  min-height: calc(100vh - 6em); // Allows menu to be full screen but no scroll //
  overflow-x: hidden; 
}
.ghost{
  visibility: hidden;
}
.page-container{
  margin: 0 10vw;
}

/* ROUTE TRANSITIONS */

.transition-group {
  position: relative;
  width: 100%;
  .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-in 700ms ;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}


/* HELPER STYLES */
.bold{
  font-weight: 700;
}

.emphasis-text{
  font-weight: 700;
  font-style: italic;
}




/* MEDIA QUERIES */
@media only screen and (max-width: 1550px)  {
  html{
    font-size: 1vw;
  }
}

@media only screen and (max-width: 1080px)  {
  .main-act-wrap{
    .act-list{
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .topic-btns{
      .topic-btn{
        margin: 0 .1em;
      }
    }
  }
}

@media only screen and (max-width: 1550px) and (orientation: portrait){
  html{
    font-size: 1.5vw;
  }

  html[lang=es-MX]{
    .module-links{
      h1{
        font-size: 1em;
      }
    }
  }

  .col-page{
    grid-template-columns: 1fr;
    .content-left{
      grid-column: unset;
      padding: 0;
    }
    .content-right{
      grid-column: unset;
    }
    .content-left{
      max-width: unset;
    }
    .video-player-container{
      margin: 0 auto; 
    }
  }

  .target-boxes{
    width: 75%;
  }
}

@media only screen and (max-width: 768px){
  html{
    font-size: 1.4vw;
  }
  .main-act-wrap{
    .act-list{
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .topic-btns{
      .topic-btn{
        margin: 0 .1em;
      }
    }
  }
  .col-page{
    grid-template-columns: 1fr;
    .content-left{
      grid-column: unset;
      padding: 0;
    }
    .content-right{
      grid-column: unset;
    }
    .video-player-container{
      margin: 0 auto;
    }
  }
  .video-player{
    height: unset;
    width: unset;
    max-width: 99%;
  }
  .target-boxes{
    width: 75%;
  }
}

@media only screen and (max-width: 450px) {
  html{
    font-size: 2.2vw;
  }
  .video-player{
    margin: 0 .5em;
  }
  .col-page{
    grid-template-columns: 1fr;
    .content-left{
      grid-column: unset;
      padding: 0;
    }
    .content-right{
      grid-column: unset;
    }
    .video-player-container{
      margin: 0 auto;
    }
  }
  .col-section{
    .support-form-container{
      padding-left: 0;
    }
  }
  .scenarios{
    grid-template-columns: 1fr 1fr;
    .scenario-card.active{
      padding: 5vh 10vw;
    }
  }

  .instruct-txt{
    margin: 1em;
  }
  .col-section{
    grid-template-columns: 1fr;
    .desc-txt{
      border-bottom: 1px solid $maroon;
      border-right: unset;
      margin-bottom: 5em;
    }
  }
  .main-act-wrap{
    .topic-btns{
      display: grid;
      grid-template-columns: 1fr;
      .topic-btn{
        width: 100%;
      }
    }
    .act-list{
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .txt-holder{
    width: 70%;
  }
  .reminder-prompt{
    font-size: .75em;
    padding: 0 6vw;
  }
  .scenario-review{
    margin: 0;
    padding: 0 6vw;
  }

  .dropdown-section:nth-child(odd),
  .dropdown-section:nth-child(even),
  .dropdown-section-alt:nth-child(odd),
  .dropdown-section-alt:nth-child(even){
    .inner-section,
    .inner-section-alt{
      margin: 1em 10%;
    }
    h3{
      font-size: 1.3em;
      padding: 2em 10%;
    }
  }
  .dropdown-section.panel-show{
    height: 65em;
  }
  .dropdown-section-alt.panel-show{
    height: 80em;
  }
  .alt-dropdown-section:nth-child(odd),
  .alt-dropdown-section:nth-child(even){
    h3{
      padding: 1em 2em;
    }
    .inner-section{
      margin: 1em 4em;
    }
  }
  .bookmark-topic-section:nth-child(odd),
  .bookmark-topic-section:nth-child(even){
    h3{
      padding: 1em 2em;
    }
    .inner-section{
      margin: 1em 4em;
    }
  }
  .bookmark-topic-section.panel-show{
    height: 60em;
  }
}

@media only screen and (max-width: 450px) and (orientation: portrait){
  .slideshow-container{
    height: 35em;

    .main-image{

      .snugger{
        
        .car-img, .fight-img, .mom-img{
          
          height: 23em;
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) and (orientation: landscape){
  .content-right{
    grid-column: unset;
    align-items: unset;
  }

  .scenarios{
    .scenario-card.active{
      padding: 5vh 10vw;
    }
  }

  .instruct-txt{
    margin: 1em 3em;
  }

  .video-player{
    margin: 0 auto;
    width: 70%;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape){
  html{
    font-size: 1.2vw;
  }
  .video-player{
    width: 80%;
  }
  .dropdown-section.panel-show{
    height: 45em;
  }
  .swipe-holder{
    font-size: 1em;
    top: -3%;
    box-shadow: 0px 5px 10px 0px rgb(54, 53, 53);
    overflow: hidden;
    .swipe-img-container{
      margin: 0;
    }
  }
}


@media only screen and (min-width: 1500px) and(min-height:992px){
  html{
    font-size: .9vw;
  }
}

@media only screen and (min-width: 1650px) and (min-height: 1056px) {
  html{
    font-size: 1vw;
  }
}

@media only screen and (min-width: 1750px) and(min-height: 1120px){
  html{
    font-size: .7vw;
  }
}
@media only screen and(max-height:986px ) and (min-width:2026px){
  html{
    font-size: .7vw;
  }
}

.imageLoader{ 
  display:none;
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px){
  // .slideshow-container{
  //   height: 400px;

  // }
}
