.bsBtn{
  width: auto;
  height: 2.25em;
  margin: 1em auto;
  padding: 0em 0.8em;
  font-family: Arial;
  font-weight: 700;
  font-size: 1.9em; 
  letter-spacing: 0.04em;
  border-radius: 0.6em;
  border: 0.065em solid #059ddb;
  color: #059ddb;
  background-color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  .iconSpan{
    display: flex;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-right: .1em;
    .iconHolder{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 1.5em;
      width: 1.5em;
      margin-right: .8em;
      padding: .1em;
      border: .107em solid #059ddb;
      svg{
        fill: #059ddb;
        stroke: #059ddb;
        height: 100%;
        width: 100%;
      }
    } 
  } 
}

.bsBtn.altColor{
  border: 0.065em solid #059ddb;
  color: #fff;
  background-color: #059ddb;
  .iconHolder{
    border: .107em solid #fff;
    svg{
      fill: #fff;
      stroke: #fff;
      height: 100%;
      width: 100%;
    }
  } 
}

.disabled{
  text-decoration: line-through;
  color :#97cbdf;
  border: 0.065em solid #97cbdf;
  pointer-events: none;
  .iconSpan{
    .iconHolder{
      border: 2px solid #97cbdf;
      svg{
        fill: #97cbdf;
        stroke: #97cbdf;
      }
    }
  }
}
